import React, { Component } from "react";

class DropDownMenu extends Component {
  constructor(props) {
    // Loads the state of the component present when toggling the dropdown menu. 
    // The items seen listed within this component are not saved in the state but the parents state and loaded via the components props.
    //
    // The binded method handles the event of the end user changing their download selection.
    //
    // This components state will handle the methods needed for basic control such as the selected option, and closing error/success messages.

    super(props);
    
    this.state = {
      changeEvent: this.props.changeEvent,
      closeMsgEvent: this.props.closeMsgEvent,
      closeErrEvent: this.props.closeErrEvent
    }

    this.selection = this.selection.bind(this);
  }

  selection(e) {
    // This method changes the currently selected message, as well as removes any status message as the status can change with the selectedOp change.]
    // This also triggers the dropdown menu to close.

    this.props.changeEvent(e)
    this.props.closeMsgEvent()
    this.props.closeErrEvent()
    this.props.closeEvent()
  }

  render() {
    const options = this.props.availableDownloads.map(dat => <div id={dat.ID} key={'key_' + dat.ID} className="MenuOption" onClick={this.selection} data-testid={'downloadItem_'+dat.ID}>
        <div className="SelectionImg" id={dat.ID}>
          {dat.AppLogo !== 'NA' &&
            <img src={dat.AppLogo} alt="App Logo"/>
          }
        </div>
        <div className="SelctionTitle" id={dat.ID}>{dat.Title}</div>
      </div>)

    return(
      <div className="DropDownMenu" data-testid="drop-down-menu">
        {options}
      </div>
    );
  };
}

export default DropDownMenu;