import React, { Component } from "react";

class SelectionField extends Component {
  constructor(props) {
    // This component is responsible for holding the available download options loaded in from the API call to the backend.
    // This components state will only keep track of the currently selected download option, The components content should change
    // when a new item is selected to download.
     
    super(props);
    
    this.state = {
      toggleD: this.props.toggleD
    };
  }

  render() {
    return(
      <div className="SelectionField" onClick={this.state.toggleD} data-testid="selection-field">
        {this.props.selectedOp}
      </div>
    );
  };
}

export default SelectionField;