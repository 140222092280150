import './App.css';

import Header from './containers/Header';
import DownloadCenter from './containers/DownloadCenter';
import InfoContainer from './containers/InfoContainer';

import moboImg from './mobo.jpg';

function App() {
  return (
    <div className="App" data-testid="app-component">
      <Header />

      <div className="BodyContentContainer">
        <DownloadCenter />
        <InfoContainer />
      </div>
      <img className="Background" src={moboImg} alt="background" />
    </div>
  );
}

export default App;
