import React, { Component } from "react";

import SelectionField from '../components/SelectionField';
import DropDownMenu from '../components/DropDownMenu';
import { FaDownload } from "react-icons/fa";
import Alert from '@mui/material/Alert';

import '../styles/DownloadCenter.css';

class DownloadCenter extends Component {
 	constructor(props) {
 		super(props)

 		this.state = {
 			showSuccess: false,
 			showError: false,
 			dropToggle: false,
 			selectedOp: 'Click here to select option...',
 			availableItems: [{AppLogo: "NA", DLink: "NA", FileName: "NA", FileType: "NA", ID: "Select", Title: "Click here to select option..."}]
 		}

 		this.handleDownload = this.handleDownload.bind(this);
 		this.handleToggle = this.handleToggle.bind(this);
 		this.handleOpChange = this.handleOpChange.bind(this);
 		this.handleRemoveError = this.handleRemoveError.bind(this);
 		this.handleRemoveSuccess = this.handleRemoveSuccess.bind(this);
 	}

 	handleDownload(e) {
 		if (this.state.selectedOp !== 'Click here to select option...') {

			var fileToDownload = this.state.availableItems.find(x => x.Title === this.state.selectedOp).DLink;
			if (fileToDownload !== "NA") {
				window.open(fileToDownload, "_blank");
				this.setState(prevState => ({
					onSuccess: true
				}));
			}
		} else {
			this.setState(prevState => ({
				hasError: true
			}));
		}
 	}

 	handleToggle(e) {
 		this.setState(prevState => ({
			dropToggle: !prevState.dropToggle    
		}));
 	}

 	handleOpChange(e){
 		this.setState(prevState => ({
			selectedOp: this.state.availableItems.find(x => x.ID === e.target.id).Title
		}));
 	}

 	handleRemoveError() {
		// This method removes the current error message on the screen and is linked to the "x" on the red error messages.

		this.setState(prevState => ({
			hasError: false
		}));
	}

	handleRemoveSuccess() {
		// This method removes the current success message on the screen and is linked to the "x" on the green success message.

		this.setState(prevState => ({
			onSuccess: false
		}));
	}

 	componentDidMount() {
		// The API call to the backend will have the response code checked, if it is not deemed as a valid success code, such as 200
		// an error will be raised and availableDownloads will be replaced with a single item containing and error mesage
		//
		// Upon making a succesfull connection to the API, the data is stored in the components availableDownloads state variable and mounted,
		// making it remain on the page until the page is refreshed.

		fetch("https://downloads.advcustomerservice.com/available").then((response) => {
		  if (response.ok) {
		    return response.json();
		  } else {
		    throw new Error('Something went wrong');
		  }
		})
		.then((responseJson) => {
		  // Do something with the response
		  console.log(responseJson)
		  this.setState({
	    		availableItems: responseJson
	  		});
		})
		.catch((error) => {
		  this.setState(prevState => ({
				availableItems: [{AppLogo: "NA", DLink: "NA", FileName: "NA", FileType: "NA", ID: "Error", Title: "Error loading options"}]
			}));
		});
	}

	render() {
		return (
			<div className="DownloadCenter" data-testid="download-bar">

				<div className="DownloadBarInfo">
					Download Assistant
				</div>
				<div className="DropContainer">
					<div className="ErrorMsg">
						{this.state.hasError ? <Alert role="error-alert" severity="error" onClose={() => {this.handleRemoveError()}}>Please select a valid software from the list</Alert> : ''}
					</div>
					
					<div className="SuccessMsg">
						{this.state.onSuccess ? <Alert role="success-alert" severity="success" onClose={() => {this.handleRemoveSuccess()}}>Thank you for downloading {this.state.selectedOp}</Alert> : ''}
					</div>
					
					<SelectionField 
						selectedOp={this.state.selectedOp}
						toggleD={this.handleToggle}
					/>

					<div className="EnterButton" onClick={this.handleDownload} data-testid="download-button">
						<FaDownload />
					</div>

					{this.state.dropToggle ? <DropDownMenu 
												closeEvent={this.handleToggle} 
												changeEvent={this.handleOpChange} 
												closeMsgEvent={this.handleRemoveSuccess} 
												closeErrEvent={this.handleRemoveError}
												availableDownloads={this.state.availableItems}
												/> : ''}

				</div>
			</div>
		);
	}
};

export default DownloadCenter;