import React, { Component } from "react";

import '../styles/InfoContainer.css';

class InfoContainer extends Component {
	render() {
		return (
			<div className="InfoContainer" data-testid="info-container">
					<div className="InfoPanelContainer">

						<div className="InfoPanelLeft">
							<div className="PanelTitle">Our Mission</div>
							<div className="PanelDescription">
								Here at Advantage we take pride in our ability to help others. We strive for simple, painless interactions, intended to improve our clients experiences with their computers.
							</div>
						</div>

						<div className="InfoPanelRight">
							<div className="PanelTitle"><div className="PanelTitleWording">Help Guide's</div><a href="https://docs.advcustomerservice.com" className="PanelSeeAllLink">All Guides &#8594;</a></div>
							<div className="PanelLinks">
								<a rel="noreferrer" href="https://docs.advcustomerservice.com/guide/1" className="HelpLink">First Time Emsisoft Installation</a>
								<a rel="noreferrer" href="https://docs.advcustomerservice.com/guide/2" className="HelpLink">Renewing an Emsisoft Licsense</a>
								<a rel="noreferrer" href="https://docs.advcustomerservice.com/guide/3" className="HelpLink">Installing Office Starter 2010</a>
								{/*<a rel="noreferrer" href="#" className="HelpLink">Activating Windows 10</a>*/}
								<a rel="noreferrer" href="https://docs.advcustomerservice.com/guide/4" className="HelpLink">Adding Ad-Block to Google Chrome</a>
								<a rel="noreferrer" href="https://docs.advcustomerservice.com/guide/5" className="HelpLink">Configuring Open DNS</a>
							</div>
						</div>
					</div>
					
					<div className="InfoTitle">
						<div className="Info-Phone-Number"><i className="fa fa-phone"></i>&#160;&#160;(520)-290-0595</div>
						<div className="Info-Help-Title">Helping Tucson for 40+ years</div>
						<a className="Info-google-review" href="https://search.google.com/local/writereview?placeid=ChIJ-aFw2ydp1oYRVzNBps-tvCY">Leave us a review &#8594;</a>
					</div>
				</div>
		);
	}
};

export default InfoContainer;