import React, { Component } from "react";

import '../styles/Header.css';

class Header extends Component {
	render() {
		return (
			<div className="Header" data-testid="header-container">
				<div className="CompanyTitle">Advantage Micro</div>
        		<div className="TitleDescription">Customer Service</div>
			</div>
		);
	}
};

export default Header;